<template>
	<div data-route>
		<page-header
			heading="Checkout error"
		/>
		<div data-element="main">
			<p>There was a problem completing your checkout.</p>
			<btn
				size="xl"
				:route="`/browse-courses/${getCourseId}`"
				text="Try again"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import Btn         from '@/components/ui/Btn';

	export default {
		metaInfo: {
			title: 'Checkout error'
		},
		components: {
			PageHeader,
			Btn
		},
		data: () => ({
		}),
		computed: {
			getCourseId () {
				return this.$route.query.id;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					},
					{
						path: '/browse-courses/checkout-cancelled',
						text: 'Checkout error'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {
		[data-element='main'] {
			p {
				font-size:rem(12);
				margin:0 0 rem(18);
			}
			[data-component='btn'] {
				float:left;
				width:auto;
				margin:0 auto 0 0;
			}
		}
	}
</style>
